export const UddannelseData = [
  {
    id: 1,
    year: 2023,
    title: 'Certificeret terapeut i integrativ psykoterapi',
    source: 'Life Academy'
  },
  {
    id: 2,
    year: 2017,
    title: 'Cand.mag. med speciale i køn, kultur og evolutionspsykologi',
    source: 'Aarhus Universitet'
  }
]